import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})

export class SkillAndCredentialService {

  constructor(private http: HttpBaseService) { }

  skillsExist!: boolean;
  credentialsExist!: boolean;

  setSkills(value: boolean){
    this.skillsExist = value;
  }

  getSkills(){
    return this.skillsExist;
  }

  setCredentials(value: boolean){
    this.credentialsExist = value;
  }

  getCredentials(): boolean{
    return this.credentialsExist;
  }

}