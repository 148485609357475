import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { FileUploadService } from './file-upload.service';
import { Observable, lastValueFrom } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private documentInfo: any = {};

  constructor(private http: HttpBaseService, private storageService: StorageService, private fileUploadService: FileUploadService) { }

  getDocumentInfoByKey(key: string): any {
    return this.documentInfo[key];
  }

  setDocumentInfoByKey(key: string, value: any) {
    this.documentInfo[key] = value;
  }

  getDocuSignTemplates(projectId: string, searchText: string, limit?: number, offset?: number) {
    return this.http.get('documents/docuSign/project/'+projectId +'?limit='+ limit+'&offset='+offset  +'&searchText='+searchText);
  }

  getFirmDocuSignTemplates(firmId: string, searchText: string, limit?: number, offset?: number) {
    return this.http.get('documents/docuSign/firm/'+firmId+'?limit='+ limit+'&offset='+offset  +'&searchText='+searchText);
  }

  getDocuSignDocument(templateId: string, documentId: string) {
      const headers = new HttpHeaders().set('Accept', 'application/pdf').set('Content-Type', 'application/json',);
      return this.http.get('documents/docuSign/template/'+templateId+'/document/'+documentId+'?noCache='+new Date().getTime(), { headers, responseType: 'arraybuffer' });
  }

  uploadDocument(file: any, fileOpts: any) {
    return this.fileUploadService.fileUpload(file, fileOpts, 'documents');
  }

  uploadDocumentMultiple(files: any, fileOpts: any) {
    return this.fileUploadService.fileUploadMultiple(files, fileOpts, 'documents');
  }

  uploadResumeDocument(projectId: string, file: any, opts: any): Observable<any>{
    return this.fileUploadService.fileUpload(file, opts, `projects/${projectId}/documents`);
  }

  updateResumeDocument(id: string, payload: {userTitle: string, docTitle: string}): Promise<any>{
    return lastValueFrom(this.http.put(`documents/${id}`, payload));
  }

  getDocuments() {
      const params = {
        noCache: new Date().getTime()
      }
      return this.http.get('documents',{ params });
  }

  getFirmReviewerDocuments(params: any) {
      return this.http.get('documents/firmReviewer?firmId='+params.firmId+'&reviewerId='+params.reviewerId);
  }


  getDocumentsByUserId(userId: string) {
      return this.http.get('documents/users/' + userId);
  }

  getMyCurrentDocuments(projectId: string) {
    return lastValueFrom(this.http.get('my/currentDocuments?projectId='+projectId));
  }

  getDocumentsByIds(documentIds: string) {
      return this.http.get('documents/retrieveBy/Ids?id=' + documentIds);
  }

  getDocumentsById(documentId: string) {
      //document/:filename
      return this.http.get('documents/document/' + documentId);
  }

  getDocumentPathById(documentId: string): string | null {
    let path = null;
    if(documentId){
      path = environment.apiUrl + 'documents/' + documentId + '/download?token=' + this.storageService.getToken();
    }
    return path;
  }

  downloadUserResume(userId?: string, isAdmin?: boolean) {
      let query = `token=${this.storageService.getToken()}`;
      if(userId){
          query += `&userId=${userId}`;
      }
      query += `&isAdmin=${(isAdmin ? 1 : 0)}`;
      return `${environment.apiUrl}documents/downloadUserResume?'${query}`;
  }

  downloadInvoiceUrl(invoiceId: string, userId: string) {
      let query = 'token=' + this.storageService.getToken();
      if(userId) {
          query += '&userId=' + userId;
      }
      query += '&invoice=' + invoiceId;
      return `${environment.apiUrl}documents/downloadInvoice?${query}`;
  }

  deleteDocument(id: string) {
      return this.http.delete(`documents/${id}`);
  }

  updateDocumentUserTitle(id: string, userTitle: string, docTitle: string) {
      let docInfo = { userTitle : userTitle, docTitle: docTitle }
      return this.http.put('documents/' + id, docInfo);
  }

  updateFirmDocumentDetails(payload: any) {
      let docInfo = { docInstructions : payload.docInstructions, docTitle: payload.docTitle }
      return this.http.put('firms/' + payload.firmId + '/documents/' + payload.docId, docInfo);
  }

  updateTemplateDocumentFieldsConfig(projectId: string, payload: any) {
      return this.http.post('documents/docuSign/project/'+ projectId+'/updateTemplateFieldsConfig', payload);
  }

  getEnvelopeRecipientView(envelopeId: string, clientHostUrl: any): Promise<any> {
    const clientUrl = encodeURI(clientHostUrl);
    return lastValueFrom(this.http.get('documents/docuSign/envelope/' + envelopeId +'/reciepientViewUrl?clientUrl='+clientUrl));
  }

  getDocuSignDownloadUrl(projectId: string, roleId: string) {
    return environment.apiUrl+'documents/docuSign/project/' + projectId + '/role/'+ roleId+'/download?token=' + this.storageService.getToken();
  }
  
  deleteDocuSignTemplate(templateId: string, docSignTemplateId: string) {
    return this.http.query('delete', 'documents/docuSign/template', null, {
      headers: {
        'X-DATA-MPLACE': JSON.stringify({templateId: templateId, docSignTemplateId: docSignTemplateId})
      }
    });
  }

  deleteDocuSignDocument(documentId: string, templateId: string, docSignTemplateId: string) {
    return this.http.query('delete', 'documents/docuSign/template/document', null, {
      headers: {
        'X-DATA-MPLACE': JSON.stringify({documentId: documentId, templateId: templateId, docSignTemplateId: docSignTemplateId})
      }
    });
  }

  addAdditionalProjectTemplateDocuments(projectId: string, firmId: string, templateObject: any) {
    const templateInfo = {
        templateObject: templateObject,
    };
    return lastValueFrom(this.http.post(`documents/docuSign/project/${projectId}/firm/${firmId}/create`, templateInfo));
  }

  updateProjectDocuSignTemplate(projectId: string, firmId: string, templateObject: any) {
    const templateInfo = {
      templateObject: templateObject,
  };
    return lastValueFrom(this.http.put(`documents/docuSign/project/${projectId}/firm/${firmId}`, templateInfo));
  }

  getEnvelopeDocumentsListing(projectId: string, role: string, reviewerId: string, positionId: string, downloadAll: boolean, additionalEnvelopes: boolean = false, isHeaderInclude = true){
    let url = `documents/docuSign/project/${projectId}/role/${role}/reviewer/${reviewerId}/position/${positionId}`;
    if(downloadAll) {
        url+='?downloadAll=all';
    }
    if(additionalEnvelopes) {
        url+='?additionalEnvelopes=true';
    }

    if(isHeaderInclude){
      const headers = new HttpHeaders().set('Accept', 'application/pdf').set('Content-Type', 'application/json',);
      return lastValueFrom(this.http.get(url, { headers, responseType: 'arraybuffer' }));
    }

    return lastValueFrom(this.http.get(url));
  }

  viewEnvelopeDocument(envelopeId: string, documentId: string) {
    const url = 'documents/docuSign/envelope/' + envelopeId + '/document/' + documentId;
    const headers = new HttpHeaders().set('Accept', 'application/pdf').set('Content-Type', 'application/json',);
    return lastValueFrom(this.http.get(url, { headers, responseType: 'arraybuffer' }));
  }

  getFileReaderObject(blob: any): Promise<any>{
    const reader = new FileReader();
    
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(new Error('Failed to read the blob content'));
      };
      
      reader.readAsText(blob);
    });
  }
}
