import { Reviewer } from "./reviewer.interface";
import { createFeatureSelector, createSelector} from '@ngrx/store';

export const getReviewer= createSelector(
    createFeatureSelector('reviewer'),
    (store:Reviewer)=>{
        return store;
    }
);


export const getReviewerTenants = createSelector(
    createFeatureSelector('reviewer'),
    (reviewer:Reviewer)=>{
        return reviewer.tenants;
    }
);
